.form-control {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0);
    height: 40px;
    border-radius: 4px !important;
    border-color: rgb(168, 168, 168) !important;
    border-width: 1px !important;
    padding-inline: 10px !important;
    font-weight: 600;
  }
  .react-tel-input {
    margin-top: 10px !important;
    margin-left: 10px !important;
  }
  