.otp-group {
    display: flex;
    width: 100%;
    max-width: 180px;
    column-gap: 10px;
  }
  
  .otp-input {
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }